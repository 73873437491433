import { HttpInterceptorFn } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, switchMap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../service/spinner.service';
import { ToastService } from '../service/toast.service';
import { SignInService } from '../service/twofa/signin.service';

export const retryCount = 1;
export const retryWaitMilliSeconds = 1000;

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const authenticationService = inject(AuthenticationService);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const spinnerService = inject(SpinnerService);
  const signInService = inject(SignInService);
  return next(request)
    .pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (
              count <= retryCount &&
              (error.status === 0 || (error.status === 400 && !error.error))
            ) {
              return of(error);
            }
            return throwError(() => error);
          }),
          delay(retryWaitMilliSeconds)
        )
      )
    )
    .pipe(
      catchError(err => {
        if (request.url.includes('GetImageProfil')) return;
        if (err.status === 304)
          return;
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 response try refresh token
          const refreshToken = authenticationService.getRefreshToken();
          if (refreshToken) {
            return signInService.refreshToken(refreshToken).pipe(   

              // Retry unauthorize request
              switchMap(value => {
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${value?.token}`
                  }
                });
                authenticationService.login(value?.token, value?.refreshToken)
                return next(request);
              }),
              catchError(error => {
                // Handle refresh token failure, log out the user
                authenticationService.logout(null);
                return throwError(() => error);
              })
            );
          }
          authenticationService.logout(null);
        }

        const error = JSON.stringify(err);
        spinnerService.hide();
        let erreur = err.error.error ? err.error.error : err.error;
        if (erreur instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            erreur = reader.result;
            if (!environment.production) {
              toastService.showError('Erreur', erreur);
            } else
              toastService.showError(translateService.instant('Error'), erreur);
          };
          reader.readAsText(erreur);
        } else {
          if (err.status === 504) {
            toastService.showError(
              translateService.instant('Timeout Error'),
              ''
            );
          } else if (environment.production && err.status === 500) {
            toastService.showError(
              translateService.instant('Internal Error'),
              ''
            );
          } else if (environment.production && err.status === 400) {
            toastService.showError(translateService.instant('Error'), erreur);
          } else if (environment.production && err.status === 408) {
            toastService.showError(
              translateService.instant('Error'),
              translateService.instant('Error.Timeout.408')
            );
          } else if (
            environment.production &&
            err.status !== 0 &&
            err.status !== 401
          ) {
            toastService.showError(translateService.instant('Error'), '');
          } else if (!environment.production) {
            toastService.showError('Erreur', error);
          }
        }
        return throwError(() => error);
      }).bind(this)
    );
};
