import { TypeJeton } from '../../enum/typeJeton';
import { SearchCriteria } from '../searchCriteria';
import { Jetons } from './jetons';

export class User {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public password: string;
  public type: number;
  public token: string;
  public companyName: string;
  public subType: number;
  public multiSubType: number[];
  public other: string;
  public lastAccess: Date;
  public creation: Date;
  public typeName: string;
  public subTypeName: string;
  public role: string;
  public active: number;
  public searchCriteria: SearchCriteria;
  public idStripeProduct: string;
  public subscriptionId: string;
  public numberOfToken: number;
  public jetons: Jetons[];
  public researchTotal: Date[];
  public reportTotal: Date[];
  public nbTokenSpendTotal: number;
  public renewPackageTotal: number;
  public renewPackageDate: Date;
  public totalSpend: number;
  public connectionDate: Date[];
  public connectionDateCrm: number;
  public connectionDateCrmTotal: number;
  public numberOfTokenCrm: string;
  public researchCrm: number;
  public researchTotalCrm: number;
  public reportCrm: number;
  public reportTotalCrm: number;

  public connectionDateOneWeekCrm: number;
  public researchTotalOneWeekCrm: number;
  public reportTotalOneWeekCrm: number;
  public nbTokenSpendTotalOneWeek: number;
  public profilImage: string;
  public companyNameInvoice: number;

  public overrideReportActive: number;
  public restToken: number;
  public tokenLostByMonth: number;
  public tutorial: number;
  public twoFaType: number;
  public mailingList: boolean;
  public mailingListDate: Date;

  public static fromJson(json: object): User {
    const user = new User();
    Object.assign(user, json);
    user.mailingListDate = new Date(user.mailingListDate);
    user.creation = new Date(json['creation']);
    user.creation = new Date(json['lastAccess']);
    const bidonResearch: Date[] = json['researchTotal'];
    user.researchTotal = bidonResearch.map((jsonItem) => new Date(jsonItem));
    const bidonReport: Date[] = json['reportTotal'];
    user.reportTotal = bidonReport.map((jsonItem) => new Date(jsonItem));
    const bidonDate: Date[] = json['connectionDate'];
    user.connectionDate = bidonDate.map((jsonItem) => new Date(jsonItem));

    const bidons: object[] = json['jetons'];
    user.jetons = bidons.map((jsonItem) => Jetons.fromJson(jsonItem));

    user.renewPackageTotal = user.jetons.find(
      (c) => c.type === TypeJeton.Bonus
    )?.quantity;
    if (user.renewPackageTotal === undefined) {
      user.renewPackageTotal = 0;
      user.renewPackageDate = null;
    } else
      user.renewPackageDate = new Date(
        user.jetons.find((c) => c.type === TypeJeton.Bonus)?.expiration
      );

    return user;
  }
  static formatDates(results: Date[]): any {
    results = results.map((dat) => new Date(dat));
    return results;
  }
}
