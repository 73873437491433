import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { finalize } from 'rxjs';
import { AuthenticationService } from '../service/authentication.service';
import { SpinnerService } from '../service/spinner.service';

export const JwtInterceptor: HttpInterceptorFn = (request, next) => {
  const authenticationService = inject(AuthenticationService);
  const spinnerService = inject(SpinnerService);

  const jwt = authenticationService.getJwt();
  if (!jwt || jwt === 'null' || jwt === 'undefined') {
    return next(request).pipe(
      finalize(() => {
        spinnerService.hide();
      })
    );
  } else {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwt}`
      }
    });
  }
  return next(request);
};
