import { Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { Role } from './enum/role';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./layout/authenticatedLayout/authenticatedLayout.component').then(
        (mod) => mod.AuthenticatedLayoutComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./dashboard/dashboard.component').then(
            (mod) => mod.DashboardComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test, Role.None]
        }
      },
      {
        path: 'termes',
        loadComponent: () =>
          import('./text/term/term.component').then(
            (mod) => mod.TermComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test, Role.None]
        }
      },
      {
        path: 'google-map',
        loadComponent: () =>
          import('./old/googlemap/googlemap.component').then(
            (mod) => mod.GoogleMapComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Admin]
        }
      },
      {
        path: 'sauvegardeV1',
        loadComponent: () =>
          import('./old/backup/backup.component').then(
            (mod) => mod.MyBackupComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Admin]
        }
      },
      {
        path: 'carte',
        loadComponent: () =>
          import('./map/map.component').then((mod) => mod.MapComponent),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.Visitor]
        }
      },
      {
        path: 'carte/:id',
        loadComponent: () =>
          import('./map/map.component').then((mod) => mod.MapComponent),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.Visitor]
        }
      },
      {
        path: 'recherche',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'recherche/:step',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'recherche/:step/:id',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'sauvegarde',
        loadComponent: () =>
          import('./backup/backup.component').then(
            (mod) => mod.MyBackupComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'nouvelles',
        loadComponent: () =>
          import('./news/news.component').then((mod) => mod.MyNewsComponent)
      },
      {
        path: 'nouvelle-details/:slug/:id',
        loadComponent: () =>
          import('./news-details/news-details.component').then(
            (mod) => mod.NewsDetailsComponent
          )
      },
      {
        path: 'calculateur',
        loadComponent: () =>
          import('./calculator/home/calculatorhome.component').then(
            (mod) => mod.CalculatorHomeComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'calculateur/scenario/:id',
        loadComponent: () =>
          import('./calculator/scenario/calculatorscenario.component').then(
            (mod) => mod.CalculatorScenarioComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'librairie',
        loadComponent: () =>
          import('./library/library.component').then(
            (mod) => mod.LibraryComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test, Role.None]
        }
      },
      {
        path: 'profil',
        loadComponent: () =>
          import('./profil/profil.component').then(
            (mod) => mod.ProfilComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'changer-mot-de-passe',
        loadComponent: () =>
          import('./change-password/change-password.component').then(
            (mod) => mod.ChangePasswordComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'alertes',
        loadComponent: () =>
          import('./alerts/dashboard.component').then(
            (mod) => mod.DashboardComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'resultat',
        loadComponent: () =>
          import('./result/result.component').then(
            (mod) => mod.ResultComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'batiments',
        loadComponent: () =>
          import('./buildings/buildings.component').then(
            (mod) => mod.BuildingsComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'abonnement',
        loadComponent: () =>
          import('./subscription/subscription.component').then(
            (mod) => mod.SubscriptionComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'reinitialiser-le-mot-de-passe',
        loadComponent: () =>
          import('./reset-password/reset-password.component').then(
            (mod) => mod.ResetPasswordComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'abonnement-details/:id',
        loadComponent: () =>
          import('./subscription-details/subscription-details.component').then(
            (mod) => mod.SubscriptionDetailsComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'resultatsommaire',
        loadComponent: () =>
          import('./summaryResult/summaryResult.component').then(
            (mod) => mod.SummaryResultComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      }
    ]
  },
  {
    path: 'en',
    loadComponent: () =>
      import('./layout/authenticatedLayout/authenticatedLayout.component').then(
        (mod) => mod.AuthenticatedLayoutComponent
      ),

    children: [
      {
        path: '',
        loadComponent: () =>
          import('./dashboard/dashboard.component').then(
            (mod) => mod.DashboardComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test, Role.None]
        }
      },
      {
        path: 'terms',
        loadComponent: () =>
          import('./text/term/term.component').then(
            (mod) => mod.TermComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test, Role.None]
        }
      },
      {
        path: 'google-map',
        loadComponent: () =>
          import('./old/googlemap/googlemap.component').then(
            (mod) => mod.GoogleMapComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Admin]
        }
      },
      {
        path: 'backup-v1',
        loadComponent: () =>
          import('./old/backup/backup.component').then(
            (mod) => mod.MyBackupComponent
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] }
      },
      {
        path: 'map',
        loadComponent: () =>
          import('./map/map.component').then((mod) => mod.MapComponent),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.Visitor]
        }
      },
      {
        path: 'map/:id',
        loadComponent: () =>
          import('./map/map.component').then((mod) => mod.MapComponent),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.Visitor]
        }
      },
      {
        path: 'research',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'research/:step',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'research/:step/:id',
        loadComponent: () =>
          import('./research/research.component').then(
            (mod) => mod.ResearchComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'backup',
        loadComponent: () =>
          import('./backup/backup.component').then(
            (mod) => mod.MyBackupComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'news',
        loadComponent: () =>
          import('./news/news.component').then((mod) => mod.MyNewsComponent)
      },
      {
        path: 'news-details/:slug/:id',
        loadComponent: () =>
          import('./news-details/news-details.component').then(
            (mod) => mod.NewsDetailsComponent
          )
      },
      {
        path: 'calculator',
        loadComponent: () =>
          import('./calculator/home/calculatorhome.component').then(
            (mod) => mod.CalculatorHomeComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'calculator/scenario/:id',
        loadComponent: () =>
          import('./calculator/scenario/calculatorscenario.component').then(
            (mod) => mod.CalculatorScenarioComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'library',
        loadComponent: () =>
          import('./library/library.component').then(
            (mod) => mod.LibraryComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'profil',
        loadComponent: () =>
          import('./profil/profil.component').then(
            (mod) => mod.ProfilComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'change-password',
        loadComponent: () =>
          import('./change-password/change-password.component').then(
            (mod) => mod.ChangePasswordComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'alerts',
        loadComponent: () =>
          import('./alerts/dashboard.component').then(
            (mod) => mod.DashboardComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'result',
        loadComponent: () =>
          import('./result/result.component').then(
            (mod) => mod.ResultComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      },
      {
        path: 'buildings',
        loadComponent: () =>
          import('./buildings/buildings.component').then(
            (mod) => mod.BuildingsComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Visitor, Role.Test]
        }
      },
      {
        path: 'subscription',
        loadComponent: () =>
          import('./subscription/subscription.component').then(
            (mod) => mod.SubscriptionComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'reset-password',
        loadComponent: () =>
          import('./reset-password/reset-password.component').then(
            (mod) => mod.ResetPasswordComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'subscription-details/:id',
        loadComponent: () =>
          import('./subscription-details/subscription-details.component').then(
            (mod) => mod.SubscriptionDetailsComponent
          ),
        canActivate: [AuthGuard],
        data: {
          roles: [Role.User, Role.Admin, Role.Test, Role.None]
        }
      },
      {
        path: 'summaryresult',
        loadComponent: () =>
          import('./summaryResult/summaryResult.component').then(
            (mod) => mod.SummaryResultComponent
          ),
        data: {
          roles: [Role.User, Role.Admin, Role.Test]
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    canActivate: [AuthGuard],
    data: {
      roles: [Role.User, Role.Admin, Role.BetaTest, Role.Test]
    }
  }
];
