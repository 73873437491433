import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public target: BehaviorSubject<string>;
  public visibility: BehaviorSubject<{ [key: string]: object }[]>;
  constructor(private translateService: TranslateService) {
    this.visibility = new BehaviorSubject(null);
    this.target = new BehaviorSubject('');
  }

  showSuccess(titre: string, description: string, target?: string) {
    if (titre === '') titre = 'Toast.TitleSuccess.Text';
    if (description === '')
      description = this.translateService.instant(
        'Toast.DescriptionSuccess.Text'
      );
    else {
      try {
        description = this.translateService.instant(description);
      }
      catch {
        description = description;
      }
    }
    const toast: { [key: string]: any }[] = [
      {
        title: this.translateService.instant(titre),
        content: description,
        cssClass: 'e-toast-success',
        icon: 'e-success toast-icons'
      }
    ];
    if (target && target !== '') this.target.next(target);
    this.visibility.next(toast);
  }

  showError(titre: string, description: string, target?: string) {
    if (titre === '') titre = 'Toast.TitleError.Text';
    if (description === '')
      description = this.translateService.instant(
        'Toast.DescriptionError.Text'
      );
    else {
      try {
        description = this.translateService.instant(description);
      }
      catch {
        description = description;
      }

    }
    const toast: { [key: string]: any }[] = [
      {
        title: this.translateService.instant(titre),
        content: description,
        cssClass: 'e-toast-danger',
        icon: 'e-danger toast-icons'
      }
    ];
    if (target && target !== '') this.target.next(target);
    this.visibility.next(toast);
  }

  showInfo(titre: string, description: string, target?: string) {
    if (titre === '') titre = 'Toast.TitleInfo.Text';
    if (description === '') description = 'Toast.DescriptionInfo.Text';
    else {
      try {
        description = this.translateService.instant(description);
      }
      catch {
        description = description;
      }
    }
    const toast: { [key: string]: any }[] = [
      {
        title: this.translateService.instant(titre),
        content: description,
        cssClass: 'e-toast-info',
        icon: 'e-info toast-icons'
      }
    ];
    if (target && target !== '') this.target.next(target);
    this.visibility.next(toast);
  }
}
