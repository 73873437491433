export class UserProfil {
  public id: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public companyName: string;
  public companyNameInvoice: number;
  public type: number;
  public subType: number;
  public multiSubType: number[];
  public twoFaType: number;
  public profilImage: string;
  public subscriptionId: string;
  public lang: string;
  public mailingList: boolean
  public mailingListDate: Date;

  public static fromJson(json: object): UserProfil {
    const user = new UserProfil();
    Object.assign(user, json);
    return user;
  }
}
