
export class Jetons {
  public expiration: Date;
  public quantity: number;
  public type: number;
  public toBeCanceled: number;
  public limited: number;

  public static fromJson(json: object): Jetons {
    const jeton = new Jetons()
    jeton.expiration = json['expiration'];
    jeton.quantity = json['quantity'];
    jeton.type = json['type'];
    jeton.toBeCanceled = json['toBeCanceled'];
    jeton.limited = json["limited"];
    return jeton;
  }
}
