import { GoogleMapPos } from './googleMapPos';

export class GoogleMapStats {
  public googleMapPos: GoogleMapPos;
  public periods: number;
  public type: boolean[];
  public startDate: Date;
  public endDate: Date;
  public zip: string;
  public periodHistoric: boolean;
  public characteristic: any[];
  public isLoadResearch: boolean;
  public sizeChoice: number;
  public periodChoice: number;
  public name: string;

  public static fromJson(json: object): GoogleMapStats {
    const google = new GoogleMapStats();
    google.googleMapPos = json['googleMapPos'];
    google.periods = json['periods'];
    google.type = json['type'];
    google.startDate = json['startDate'];
    google.endDate = json['endDate'];
    google.zip = json['zip'];
    google.periodHistoric = json['periodHistoric'];
    google.characteristic = json['characteristic'];
    google.name = json['name'];
    return google;
  }
}
