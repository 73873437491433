import { Inclusions } from '../dto/research/inclusions';

export const features: Inclusions[] = [
  {
    id: 1,
    name: 'electricity',
    icon: 'ic_electric.svg',
    alt: 'Electricity'
  },
  {
    id: 2,
    name: 'heating',
    icon: 'ic_heating_black.svg',
    alt: 'Heating'
  },
  {
    id: 3,
    name: 'internet',
    icon: 'ic_internet1.svg',
    alt: 'Internet'
  },
  {
    id: 4,
    name: 'storageLocker',
    icon: 'ic_inclusion_storage.png',
    alt: 'Storage'
  },
  {
    id: 5,
    name: 'furnished',
    icon: 'ic_furniture.svg',
    alt: 'Furnished'
  },
  {
    id: 9,
    name: 'elevator',
    icon: 'ic_elevator.svg',
    alt: 'Elevator'
  },
  {
    id: 10,
    name: 'airConditioning',
    icon: 'ic_air-conditioner.svg',
    alt: 'Air conditioning'
  }
];

export { Inclusions };
