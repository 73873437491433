import { features } from "../utils/inclusions"

export const HOUSE_TYPES = [
  {
    display: 'All Types',
    value: 'HOUSE_TYPE_ALL',
    checked: true,
    isShowed: true
  },
  { display: 'Studio', value: 'studio', checked: false, isShowed: false },
  { display: '1 Bedroom', value: 'room1', checked: false, isShowed: false },
  { display: '2 Bedroom', value: 'room2', checked: false, isShowed: false },
  { display: '3 Bedroom', value: 'room3', checked: false, isShowed: false },
  { display: '4 Bedroom', value: 'room4', checked: false, isShowed: false }
]

export const HOUSE_QUALITIES = [
  // { display: 'All Types' , value: 'QUALITY_ALL', checked: false, isShowed: true},
  {
    display: 'App. Exist',
    value: 'existed',
    checked: false,
    isShowed: false
  },
  {
    display: 'App. Renovated',
    value: 'renovated',
    checked: false,
    isShowed: false
  },
  {
    display: 'App. Condo Style',
    value: 'condo_style',
    checked: false,
    isShowed: false
  },
  {
    display: 'App. New',
    value: 'new',
    checked: false,
    isShowed: false
  }
]

export const HOUSE_INCLUSION = features.map(c=> ({
  display: c.alt,
  value: c.name,
  icon: c.icon,
  checked: false,
  isShowed: false
}))

export const SUMMARY_PRICE_BAR = {
  ZONE_1: {
    MIN: 0,
    MAX: 649,
    VALUE: 10,
  },
  ZONE_2: {
    MIN: 650,
    MAX: 899,
    VALUE: 30,
  },
  ZONE_3: {
    MIN: 900,
    MAX: 1249,
    VALUE: 50,
  },
  ZONE_4: {
    MIN: 1250,
    MAX: 1849,
    VALUE: 70,
  },
  ZONE_5: {
    MIN: 1850,
    MAX: 100000,
    VALUE: 90,
  },
}

export const URL_ENCODE_MAPPING = {
  '=': 'C---3D',
  '?': 'C---3F',
  '&': 'C---26',
  '/': 'C---2F',
  '#': 'C---23',
  ' ': 'C---20',
  '@': 'C---40',
  '!': 'C---21',
  '$': 'C---24',
  '%': 'C---25',
  // '^': '%5E',
  // '*': '%2A',
  // '(': '%28',
  // ')': '%29',
  // '+': '%2B',
  // ',': '%2C',
  // '.': '%2E',
  // ':': '%3A',
  // ';': '%3B',
  // '<': '%3C',
  // '>': '%3E',
  // '[': '%5B',
  // ']': '%5D',
  // '`': '%60',
  // '{': '%7B',
  // '|': '%7C',
  // '}': '%7D',
  // '~': '%7E',
}
