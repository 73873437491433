export const environment = {
  apiUrl: 'https://betazipplex.ca',
  production: true,
  cookieeyes: false,
  googleKey: 'AIzaSyAjUHOfN4QnfpX-uodUO1K-jMbvF_Zc6qU',
  cookieDomain: 'betazipplex.ca',
  stripeKey:
    'pk_test_51HKOw3AvLOeXTgkLgOdIPwF5QbtYAaXePSMBp01G94HRjI6gTxbgnYzcwfmkonsUobN3PP3bMNiZiaJEFDuKjBIa005uFOgEwd',
  googleAnalytics: 'G-MHV95361M4',
  powerBi: 'test',
  mapbox:
    'pk.eyJ1IjoiZnJlZGVyaWNjb3V0dSIsImEiOiJjbGRhM2phcHgwNzVlM3ZtdXN6OGR6cmZvIn0.Zvg_uzSAF5Zfd653JpnQ4g',
  tracing: true,
  sendToSegment: false,
  hideGoogleIndex: true,
  jwtName: 'jwtBeta',
  refreshToken: 'refreshToken',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1
};
