import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AsyncDialog } from './dialogService';

@Injectable({ providedIn: 'root' })
export class NeedLoginService extends AsyncDialog < any > {
  async open(data): Promise<MatDialogRef<any>> {
    const importedModuleFile = await import(
      '../../dialogComponent/need-to-login/need-to-login.component'
    );

    // the `importedModuleFile` has all the imports of the file
    // and since one of them is `JobUserAssignDialogModule`
    // we can use it to invoke previously defined `getComponent()` method
    return this.matDialog.open(
      importedModuleFile.NeedToLoginComponent.getComponent(),
      {
        data,
        maxWidth: '800px',
        width: '90%',
        maxHeight: '90vh',
        panelClass: 'radiusModal-16px'
      }
    );
  }
}
