import { Directive } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataDialogService } from './dataDialogService';

@Directive()
export abstract class AsyncDialog<ComponentType> {
  constructor(
    protected matDialog: MatDialog,
    protected dataDialogService: DataDialogService
  ) {}

  abstract open(data): Promise<MatDialogRef<ComponentType>>;
}
