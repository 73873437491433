import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SignInService } from './twofa/signin.service';
import { DataDialogService } from './dialog/dataDialogService';
import { environment } from '../../environments/environment';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { BehaviorSubject, first, map, tap } from 'rxjs';
import { NeedLoginService } from './dialog/needLoginService';
import { TranslateService } from '@ngx-translate/core';
import { encodeRedirectURL } from '../helpers/commonHelper';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public idUser: string;
  private jwt = '';
  private role: string;
  private isRunningLoginProcess: boolean = false;
  private behaviorValidate = new BehaviorSubject(null);
  private unsubscribeLoginState = undefined;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private router: Router,
    private dataService: DataService,
    private needLoginService: NeedLoginService,
    private signInService: SignInService,
    private dialogDataService: DataDialogService,
    private authService: SocialAuthService,
  ) {}

  SetValidated(value: boolean) {
    this.behaviorValidate.next(value);
  }

  GetValidated() {
    return this.behaviorValidate.asObservable();
  }

  GetValidatedValue(): boolean {
    return this.behaviorValidate.value;
  }

  SetLoginFailed() {
    this.isRunningLoginProcess = false;
    this.SetValidated(false)
  }

  public getJwt() {
    if (this.jwt.length === 0)
      this.jwt = this.cookieService.get(environment.jwtName);
    if (this.jwt && this.jwt !== 'null' && this.jwt !== 'undefined') {
      const jwtHelper = new JwtHelperService();
      if (!jwtHelper.isTokenExpired(this.jwt)) {
        this.role = jwtHelper.decodeToken(this.jwt).role;
      } else this.jwt = '';
    } else this.jwt = '';
    return this.jwt;
  }

  public getRefreshToken() {
    return this.cookieService.get(environment.refreshToken);
  }

  public getRole() {
    this.getJwt();
    return this.role;
  }

  public isJwtExpired() {
    this.getJwt();
    if (this.jwt === '') return true;
    const jwtHelper = new JwtHelperService();
    return jwtHelper.isTokenExpired(this.jwt);
  }

  public getIdUser() {
    this.getJwt();
    if (!this.idUser) {
      const jwtHelper = new JwtHelperService();
      this.idUser = jwtHelper.decodeToken(this.jwt).name;
    }
    return this.idUser;
  }

  isLogged() {
    return this.getJwt() !== '';
  }

  navigateToDashboard(args?: { queryParams }) {
    let dashboardURL = '/en';
    if(this.translateService.currentLang === 'fr') {
      dashboardURL = '/';
    }
    this.router.navigate([dashboardURL], { queryParams: args?.queryParams ?? {} });
  }

  async needLogin(args?: { isRedirectToDashboard?: boolean, redirectURL?: string }): Promise<boolean> {
    const isLogged = this.isLogged();
    if (isLogged) return true;

    this.isRunningLoginProcess = true

    if (this.unsubscribeLoginState) {
      this.unsubscribeLoginState.unsubscribe();
    }

    this.needLoginService.open(null);

    return new Promise((resolve) => {
      this.unsubscribeLoginState = this.GetValidated().subscribe({
        next: (isLoginSuccessfully?: boolean) => {
          if(this.isRunningLoginProcess) return;

          resolve(isLoginSuccessfully ?? false);
          if(isLoginSuccessfully) {
            if(args?.isRedirectToDashboard) this.navigateToDashboard()
            if(args?.redirectURL) this.router.navigate([args.redirectURL]);
          }
        },
        error: (err) => {
          resolve(false);
        },
      });
    });
  }

  login(token: string, refreshToken: string) {
    this.jwt = token;
    this.cookieService.set(environment.jwtName, token, {
      expires: 1,
      path: '/',
      domain: null,
      secure: true,
      sameSite: 'Strict'
    });
    this.cookieService.set(environment.refreshToken, refreshToken, {
      expires: 1,
      path: '/',
      domain: null,
      secure: true,
      sameSite: 'Strict'
    })
    this.isRunningLoginProcess = false;
    this.SetValidated(true);
  }

  logout(url: string) {
    // remove user from local storage and set current user to null
    this.dataService.SetGoogleMapResearch(null);
    this.dataService.SetGoogleMapResearchPopupInfo(null);
    this.dataService.SetDrawPolygonAssistedResearch(null);

    this.dialogDataService.dialog.forEach((c) => c.close());
    this.dialogDataService.dialog = [];
    if ( this.authService["_user"])
      this.authService.signOut();

    this.signInService.signOut().subscribe();
    this.jwt = '';
    this.cookieService.delete(environment.jwtName, '/');
    this.cookieService.delete(environment.refreshToken, '/');
    this.SetValidated(false)

    let queryParams = {}
    if(url) queryParams = { redirect: encodeRedirectURL(url) };
    this.navigateToDashboard({ queryParams })
  }
}
