export class UserNotification {
  public phones: string[];
  public emails: string[];
  public phone: number;
  public email: number;
  public mobileApp: number;

  public static fromJson(json: object): UserNotification {
    const user = new UserNotification();
    user.phones = json['phones'];
    user.emails = json['emails'];
    user.phone = json['phone'];
    user.email = json['email'];
    user.mobileApp = json['mobileApp'];
    return user;
  }
}
