import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import {  tap } from 'rxjs';

export const NonceInterceptor: HttpInterceptorFn = (request, next) => {
  return next(request).pipe(
    tap(event => {
      if (event instanceof HttpResponse) {
        // Récupération du nonce depuis les en-têtes de réponse
        const nonce = event.headers.get('X-CSP-Nonce');
        if (nonce) {
          globalThis.myRandomNonceValue = nonce;
        }
      }
    })
  );
}
