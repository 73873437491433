import {
  APP_INITIALIZER,
  CSP_NONCE,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID
} from '@angular/core';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { CookieService } from 'ngx-cookie-service';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { ErrorInterceptor } from './app/helpers/error.interceptor';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { routes } from './app/app-routing.module';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeFr from '@angular/common/locales/fr';
import { JwtInterceptor } from './app/helpers/jwt.interceptor';
import { NgxStripeModule } from 'ngx-stripe';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { NonceInterceptor } from './app/helpers/nonce.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

registerLocaleData(localeFr);

registerLicense(
  'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5UdEdiWn1ddHNQQGVa'
);
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(NgxStripeModule.forRoot(environment.stripeKey)),
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload',
        enableTracing: environment.tracing
        // preloadingStrategy: PreloadAllModules
      })
    ),
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
    CookieService,
    provideHttpClient(
      withInterceptors([JwtInterceptor, ErrorInterceptor, NonceInterceptor])
    ),
    {
      provide: CSP_NONCE,
      useValue: '5Jc6_acjeY_iXOJleD3Mkw'
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    importProvidersFrom(
      NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
      NgxGoogleAnalyticsRouterModule
    ),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    importProvidersFrom(ServiceWorkerModule.register('custom-sw.js', {
      enabled: environment.production,
      // Optional configuration
    })),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '599903256716-fbfj703u1nq32ddos377ma8g31pb3k4v.apps.googleusercontent.com',
              { oneTapEnabled: false }
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('386241670720265')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ]
}).catch((err) => console.log(err));

export function HttpLoaderFactory(http: HttpClient) {
  const version = new Date().getTime(); // Timestamp unique
  return new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${version}`);
}

