import { URL_ENCODE_MAPPING } from "../constants/commonConstant";

export const encodeRedirectURL = (url: string = '') => {
  return Object.keys(URL_ENCODE_MAPPING).reduce((acc, key) => {
    return acc.replaceAll(key, URL_ENCODE_MAPPING[key]);
  }, url);
}

export const decodeRedirectURL = (url: string = '') => {
  return Object.keys(URL_ENCODE_MAPPING).reduce((acc, key) => {
    return acc.replaceAll(URL_ENCODE_MAPPING[key], key);
  }, url);
}

export const isEncodeRedirectURL = (url: string = '') => {
  return Object.values(URL_ENCODE_MAPPING).some((key) => url.includes(key));
}
