import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { decodeRedirectURL, encodeRedirectURL, isEncodeRedirectURL } from '../helpers/commonHelper';

const URL_MAPPING = {
  DASHBOARD: {
    en: '/en',
    fr: '/'
  },
  RESET_PASSWORD: {
    en: '/en/reset-password',
    fr: '/reinitialiser-le-mot-de-passe'
  },
  LOGIN: {
    en: '/en/login',
    fr: '/connexion'
  },
  REGISTER: {
    en: '/en/register',
    fr: '/enregistrement'
  },
  FORGOT_PASSWORD: {
    en: '/en/forgot-password',
    fr: '/mot-de-passe-oublie'
  },
  CONFIDENTIALITY: {
    en: '/en/confidentiality',
    fr: '/confidentialite'
  },
  TERMS: {
    en: '/en/terms',
    fr: '/termes'
  },
  NEWS: {
    en: '/en/news',
    fr: '/nouvelles'
  },
  NEWS_DETAILS: {
    en: '/en/news-details',
    fr: '/nouvelle-details'
  },
  SUBSCRIPTION_DETAILS: {
    en: '/en/subscription-details',
    fr: '/abonnement-details'
  },
  RESEARCH: {
    en: '/en/research',
    fr: '/recherche'
  },
  BUILDINGS: {
    en: '/en/buildings',
    fr: '/batiments'
  },
  ALERTS: {
    en: '/en/alerts',
    fr: '/alertes'
  },
  BACKUP_V1: {
    en: '/en/backup-v1',
    fr: '/sauvegarde-v1'
  },
  BACKUP: {
    en: '/en/backup',
    fr: '/sauvegarde'
  },
  CALCULATOR: {
    en: '/en/calculator',
    fr: '/calculateur'
  },
  CALCULATOR_SCENARIO: {
    en: '/en/calculator/scenario',
    fr: '/calculateur/scenario'
  },
  CALCULATOR_HOME: {
    en: '/en/calculator/home',
    fr: '/calculateur/accueil'
  },
  MAP: {
    en: '/en/map',
    fr: '/carte'
  },
  GOOGLE_MAP: {
    en: '/en/google-map',
    fr: '/google-map'
  },
  LIBRARY: {
    en: '/en/library',
    fr: '/librairie'
  },
  PARTNER: {
    en: '/en/partner',
    fr: '/partenaire'
  },
  SUBSCRIPTION: {
    en: '/en/subscription',
    fr: '/abonnement'
  },
  PROFIL: {
    en: '/en/profil',
    fr: '/profil'
  },
  CHANGE_PASSWORD: {
    en: '/en/change-password',
    fr: '/changer-mot-de-passe'
  },
  RESULT: {
    en: '/en/result',
    fr: '/resultat'
  },
  SUMMARY_RESULT: {
    en: '/en/summaryResult',
    fr: '/resultatSommaire'
  }
};

@Injectable({ providedIn: 'root' })
export class UrlService {
  private static url: string;
  private static switchLang: boolean;

  private unsubscribe: Subscription[] = [];
  public urlLang = '';

  public login = URL_MAPPING.LOGIN.en;
  public register = URL_MAPPING.REGISTER.en;
  public forgotPassword = URL_MAPPING.FORGOT_PASSWORD.en;
  public confidentiality = URL_MAPPING.CONFIDENTIALITY.en;
  public terms = URL_MAPPING.TERMS.en;
  public news = URL_MAPPING.NEWS.en;
  public newsDetails = URL_MAPPING.NEWS_DETAILS.en;
  public subscriptionDetails = URL_MAPPING.SUBSCRIPTION_DETAILS.en;
  public dashboard = URL_MAPPING.DASHBOARD.en;
  public research = URL_MAPPING.RESEARCH.en;
  public buildings = URL_MAPPING.BUILDINGS.en;
  public alerts = URL_MAPPING.ALERTS.en;
  public backupV1 = URL_MAPPING.BACKUP_V1.en;
  public backup = URL_MAPPING.BACKUP.en;
  public calculator = URL_MAPPING.CALCULATOR.en;
  public calculatorScenario = URL_MAPPING.CALCULATOR_SCENARIO.en;
  public calculatorHome = URL_MAPPING.CALCULATOR_HOME.en;
  public map = URL_MAPPING.MAP.en;
  public googleMap = URL_MAPPING.GOOGLE_MAP.en;
  public library = URL_MAPPING.LIBRARY.en;
  public partner = URL_MAPPING.PARTNER.en;
  public subscription = URL_MAPPING.SUBSCRIPTION.en;
  public profil = URL_MAPPING.PROFIL.en;
  public changePassword = URL_MAPPING.CHANGE_PASSWORD.en;
  public result = URL_MAPPING.RESULT.en;
  public summaryResult = URL_MAPPING.SUMMARY_RESULT.en;
  public resetPassword = URL_MAPPING.RESET_PASSWORD.en;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) {}

  SetLang(language: string) {
    this.unsubscribe.push(
      this.translateService.use(language).subscribe({
        next: (val) => {
          const lang = this.translateService.currentLang;
          if (!lang) return;
          if (lang === 'en') this.urlLang = '/en';
          else this.urlLang = '';

          this.login = URL_MAPPING.LOGIN[lang];
          this.register = URL_MAPPING.REGISTER[lang];
          this.forgotPassword = URL_MAPPING.FORGOT_PASSWORD[lang];
          this.confidentiality = URL_MAPPING.CONFIDENTIALITY[lang];
          this.terms = URL_MAPPING.TERMS[lang];
          this.news = URL_MAPPING.NEWS[lang];
          this.newsDetails = URL_MAPPING.NEWS_DETAILS[lang];
          this.subscriptionDetails = URL_MAPPING.SUBSCRIPTION_DETAILS[lang];
          this.dashboard = URL_MAPPING.DASHBOARD[lang];
          this.research = URL_MAPPING.RESEARCH[lang];
          this.buildings = URL_MAPPING.BUILDINGS[lang];
          this.alerts = URL_MAPPING.ALERTS[lang];
          this.backupV1 = URL_MAPPING.BACKUP_V1[lang];
          this.backup = URL_MAPPING.BACKUP[lang];
          this.calculator = URL_MAPPING.CALCULATOR[lang];
          this.calculatorScenario = URL_MAPPING.CALCULATOR_SCENARIO[lang];
          this.calculatorHome = URL_MAPPING.CALCULATOR_HOME[lang];
          this.map = URL_MAPPING.MAP[lang];
          this.googleMap = URL_MAPPING.GOOGLE_MAP[lang];
          this.library = URL_MAPPING.LIBRARY[lang];
          this.partner = URL_MAPPING.PARTNER[lang];
          this.subscription = URL_MAPPING.SUBSCRIPTION[lang];
          this.profil = URL_MAPPING.PROFIL[lang];
          this.changePassword = URL_MAPPING.CHANGE_PASSWORD[lang];
          this.result = URL_MAPPING.RESULT[lang];
          this.summaryResult = URL_MAPPING.SUMMARY_RESULT[lang];
          this.resetPassword = URL_MAPPING.RESET_PASSWORD[lang];

          let queryString = ''
          let currentURL = ''
          let queryParams = {}
          const redirectFLAT = `?redirect=`
          const isHasRedirect = UrlService.url.includes(redirectFLAT);

          if(isHasRedirect) {
            [currentURL, queryString] = UrlService.url.split(redirectFLAT);
            const redirectURL =  isEncodeRedirectURL(queryString) ? queryString : encodeRedirectURL(queryString)
            queryParams = { redirect: redirectURL }
          } else {
            [currentURL, queryString] = UrlService.url.split('?');

            queryParams = (queryString ?? '').split('&').reduce((params: any, param) => {
              const [key, value] = param.split('=');
              params[key] = value;
              return params;
            }, {});
          }

          const partURL = [...currentURL.split('/')].filter(e => e);
          let route = `/${partURL[0]}`
          if(route.length <= 3 && partURL[1]) route += `/${partURL[1]}`

          const multiLangURL = Object.values(URL_MAPPING).find((_url) => {
            const enURL = _url.en;
            const frURL = _url.fr;

            if ([enURL, frURL].includes(route)) return true;
            return false;
          });

          let url = URL_MAPPING.DASHBOARD[lang];
          if (multiLangURL) {
            const tempURL = multiLangURL[lang];

            switch (tempURL) {
              case this.newsDetails: {
                const id = partURL.at(-1);
                const slug = partURL.at(-2);
                url = `${this.newsDetails}/${slug}/${id}`;
                break;
              }

              case this.subscriptionDetails: {
                const id = currentURL.substring(
                  currentURL.lastIndexOf('/') + 1
                );
                url = `${this.subscriptionDetails}/${id}`;
                break;
              }

              case this.calculatorScenario: {
                const id = currentURL.substring(
                  currentURL.lastIndexOf('/') + 1
                );
                url = `${this.calculatorScenario}/${id}`;
                break;
              }

              default:
                url = multiLangURL[lang];
                break;
            }
          }

          if (UrlService.switchLang && url !== UrlService.url) {
            this.activatedRoute.queryParams
              .pipe(take(1))
              .subscribe((params) => {
                this.router.navigate([`/${url}`], {
                  queryParams: queryParams
                });
              });

            UrlService.switchLang = false;
          }

          this.cookieService.set(
            '.AspNetCore.Culture',
            'c=' +
              this.translateService.currentLang +
              '|uic=' +
              this.translateService.currentLang,
            {
              expires: 7,
              path: '/',
              domain: null,
              secure: true,
              sameSite: 'Strict'
            }
          );
          if (this.authenticationService.getJwt() !== '') {
            this.userService
              .setLanguage(this.translateService.currentLang)
              .subscribe();
          }
        },
        complete: () => {
          this.unsubscribe.forEach((c) => c.unsubscribe());
          this.unsubscribe = [];
        }
      })
    );
  }

  switchLang(lang: string = null) {
    let language;
    if (!lang) {
      language = 'fr';
      if (this.translateService.currentLang === 'fr') {
        language = 'en';
      }
    } else language = lang;

    UrlService.url = this.location.path();
    UrlService.switchLang = true;
    this.SetLang(language);

    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.lang = language;
    }
  }

  setLangLoad(lang: string) {
    UrlService.url = this.location.path();
    UrlService.switchLang = true;

    this.SetLang(lang);
  }

  getUrlMenu(id: string) {
    if (id === 'dashboard') return this.dashboard;
    else if (id === 'research') return this.research;
    else if (id === 'buildings') return this.buildings;
    else if (id === 'alerts') return this.alerts;
    else if (id === 'backup-v1') return this.backupV1;
    else if (id === 'backups') return this.backup;
    else if (id === 'news') return this.news;
    else if (id === 'calculator') return this.calculator;
    else if (id === 'map') return this.map;
    else if (id === 'google-map') return this.googleMap;
    else if (id === 'library') return this.library;
    else if (id === 'partner') return this.partner;
    else if (id === 'subscription') return this.subscription;
    else if (id === 'profil') return this.profil;
    else if (id === 'terms') return this.terms;
  }

  checkNeedLoginToRedirect() {
    const redirectFLAT = `?redirect=`
    const url = this.location.path();
    const [_, queryString] = url.split(redirectFLAT);
    const redirectURL =  decodeRedirectURL(queryString)
    if(redirectURL) {

      const lang = this.translateService.currentLang;
      const isLogged = this.authenticationService.isLogged()
      const multiLangURL = Object.values(URL_MAPPING).find((_url) => {
        const enURL = _url.en
        const frURL = _url.fr

        if([enURL, frURL].includes(redirectURL)) return true
        return false
      })

      const redirectUrlByCurrentLanguage = multiLangURL?.[lang] ?? URL_MAPPING.DASHBOARD[lang]

      if(isLogged) return this.router.navigate([redirectUrlByCurrentLanguage])
      this.authenticationService.needLogin({ redirectURL: redirectUrlByCurrentLanguage })
    }
  }
}
